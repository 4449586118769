import { Platform } from "@snowplow/browser-tracker";
import { Tv2NoLabradorMetadata } from "../../snowtype/snowplow";

export function isIOS(): boolean {
  return !!window?.webkit?.messageHandlers?.getAppId;
}

export function isAndroid(): boolean {
  return typeof window.TV2JSBridge !== 'undefined' && typeof window.TV2JSBridge?.getAppId === 'function';
}

export function getPlatform(defaultPlatform: Platform): Platform {
  return (isIOS() || isAndroid()) ? "app" : defaultPlatform;
}

export async function getAppId(defaultAppId: string): Promise<string> {
  if (isIOS()) {
    return await getIOSAppId() || defaultAppId;
  } else if (isAndroid()) {
    return await getAndroidAppId() || defaultAppId;
  } else {
    return defaultAppId;
  }
}

async function getIOSAppId(): Promise<string | null> {
  if (window.webkit?.messageHandlers?.getAppId) {
    const response = await window.webkit.messageHandlers.getAppId.postMessage('getAppId');
    return response !== '' ? response : null;
  }
  return null;
}

async function getAndroidAppId(): Promise<string | null> {
  if (window.TV2JSBridge?.getAppId) {
    return window.TV2JSBridge.getAppId();
  }
  return null;
}


export const transformMetadata = (data: Tv2NoLabradorMetadata): Tv2NoLabradorMetadata => {
  if (data.tags) {
      if (Array.isArray(data.tags.sectionPath)) {
          if (data.tags.sectionPath.length === 0) {
              data.tags.sectionPath = "";
          } else {
              data.tags.sectionPath = data.tags.sectionPath.join(",");
          }
      }
  }

  if (data.assetId) {
      if (!Array.isArray(data.assetId)) {
          data.assetId = [data.assetId];
      }

      data.assetId = data.assetId.map(id => Math.floor(id));
  }

  return data;
}
